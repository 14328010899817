<template>
  <div class="hello">
    <div>
      <h2>后浪商学院-性格测试</h2>
   </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .hello {
    color: #fff;
    font-size: 20px;
    line-height: 120px;
    text-align: center;
    background-color: rgb(29, 20, 199)  
  }

  .hello h2 {
    margin: 0;
  }
</style>
