<template>
  <div class="home">
    <!--<img alt="Vue logo" src="../assets/logo.png">-->
    <HelloWorld msg="Welcome to Your Vue.js App" />
    <h2>请在尽量在15分钟内完成</h2>
    <h2>你的感觉胜于一切</h2>
    <div style="max-width: 228px;margin: 0 auto;">
    <van-field
      v-model="username"
      required
    
      :clickable="true"
      name="姓名"
      label="姓名"
      placeholder="请填写您的大名"
      :rules="[{ required: true, message: '请填写您的大名' }]"
    />
    </div>
    <van-button
      type="primary"
      size="normal"
      icon="arrow"
      :loading="btnIsLoading"
      v-show="btnIsLoading"
      round
    ></van-button>
        <van-button
      type="primary"
      size="normal"
      @click="clickHandle"
      v-show="!btnIsLoading"
      round
    >开始</van-button>
    <!--
    <router-link
      
      v-show="!btnIsLoading"
      class="router-link-active router-link-exact-active"
      @click="clickHandle"
      >开始</router-link>
    -->
    <h3>请输入姓名，并点击“开始”进行测试</h3>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";
import { showDialog } from "vant";
import $http from "@/utils/request";

export default {
  name: "HomeView",
  components: {
    HelloWorld,
  },

  data() {
    return {
      btnIsLoading: true,
      username: '',
    };
  },

  created() {
    //考虑用户回退的情况，这个时候，其实openid已经有了，就不需要再用code去后台换，不然会报错
    // 如果openid存在，则直接让用户可以进行下一页
    if (null == this.$store.state.openid || "" == this.$store.state.openid) {
      this.exchangeCode();
    } else {
      this.btnIsLoading = false;
    }
  },

  methods: {
    getQueryString(name) {
      // const url = location.search; // 项目中可直接通过search方法获取url中"?"符后的字串
      let url = window.location.href;
      if (url.indexOf("?") != -1) {
        url = url.split("?")[1];
      } else {
        return null;
      }

      let obj = {}; // 声明参数对象
      let arr = url.split("&"); // 以&符号分割为数组
      for (let i = 0; i < arr.length; i++) {
        let arrNew = arr[i].split("="); // 以"="分割为数组
        obj[arrNew[0]] = arrNew[1];
      }
      return obj[name];
    },

    exchangeCode() {
      let code = this.getQueryString("code");
      console.log("code >>>> " + code);
      if (null == code || "" == code) {
        showDialog({
          title: "温馨提示",
          message: "服务器开小差了，无法获取数据授权code",
        });
      } else {
        // 获取code成功

        let codeUrl = "/api/wx/mp/nine/exchangeCode";
        $http({ url: codeUrl, method: "post", data: { code: code } })
          .then((resp) => {
            if (0 == resp.data.code) {
              // console.log('>>>>>>>>>> hv openid : ' + resp.data.openid)
              // console.log('>>>>>>>>>> resp : %o', resp)
              this.$store.commit("changeOpenid", resp.data.data.openid);
              this.btnIsLoading = false;
            } else {
              showDialog({
                title: "温馨提示",
                message: "服务器开小差了，无法获取数据授权openid",
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    clickHandle(event){
      event.preventDefault();
      if(null == this.username || '' == this.username){
        alert('请输入您的大名！');
        return;
      } else {
        this.$store.commit("changeUserName", this.username);
        this.$router.push('/TopicList');
      }
      //


    }
  },
};
</script>

<style scoped>
a {
  display: inline-block;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  line-height: 50px;

  padding: 10px;
  color: #fff;
  background-color: rgb(36, 60, 215);
}

a:hover {
  background-color: rgb(5, 20, 119);
}

.van-button--primary {
  background-color: rgb(36, 60, 215);
  padding: 10px;
  height: 70px;
  width: 70px;
}
</style>
