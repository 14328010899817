import { createStore } from "vuex";

export default createStore({
  state: {
    calcRs: {},
    openid: '',
    userName: '',
    rsId: 0,
  },
  getters: {
    doneCalcRs(state) {
      return state.calcRs;
    },
    getOpenid(state){
      return state.openid;
    },
    getUserName(state){
      return state.userName;
    }
    ,
    getRsId(state){
      return state.rsId;
    }
  },
  mutations: {
    changeCalcRs(state, payload) {
      state.calcRs = payload;
    },

    changeOpenid(state, payload) {
      state.openid = payload;
    },

    changeUserName(state, payload) {
      state.userName = payload;
    },
    
    changeRsId(state, payload) {
      state.rsId = payload;
    },
  },
  actions: {
    changeCalcRs(ctx, payload) {
      ctx.commit("changeCalcRs", payload);
    },
    changeOpenid(ctx, payload) {
      ctx.commit("changeOpenid", payload);
    },
    changeUserName(ctx, payload) {
      ctx.commit("changeUserName", payload);
    },
    changeRsId(ctx, payload) {
      ctx.commit("changeRsId", payload);
    }
  },
  modules: {},
});
